























































































import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

import { ChargingStationsMapType } from "./ChargingStationsMapType.types";

export default defineComponent({
  name: "ChargingStationsMap",
  components: {},
  props: {
    records: {
      type: Array,
      default: [],
    },
  },

  setup(props) {
    const open = ref(false);
    var center = { lat: 51.4712593, lng: 6.8577677 };
    const infoWindowPos = ref();
    const infoOptions = ref({
      content: "",
      pixelOffset: {
        width: 0,
        height: -35,
      },
    });

    const markers = computed(() =>
      props.records.map((record: any) => ({
        position: {
          lat: Number(record.data.latitude),
          lng: Number(record.data.longitude),
        },
        icon: {
          url: require(`@/assets/images/${record.data.positiontype}.png`),
          size: { width: 45, height: 58, f: "px", b: "px" },
          scaledSize: { width: 40, height: 52, f: "px", b: "px" },
        },
        name: record.data.name,
        street: record.data.street,
        streetnumber: record.data.streetnumber,
        zipcode: record.data.zipcode,
        city: record.data.city,
      }))
    );

    function clickMarker(
      pos: any,
      name: string,
      street: string,
      streetnumber: string,
      zipcode: string,
      city: string
    ) {
      open.value = true;
      infoWindowPos.value = pos;
      infoOptions.value.content = `<b class="text-base">${name}</b><br/> ${street} ${streetnumber}<br/> ${zipcode} ${city} <br/> <a class="inline-block text-base hover:no-underline btn btn-secondary mt-2 outline-none focus:shadow-none" href="http://maps.google.de/maps?q=${street}+${streetnumber},+${zipcode}+${city}"  target="_blank" rel="noopender">Auf Google Maps anzeigen</a>`;
    }
    const showChargingMap = ref((window as any).Cookiebot.consent.preferences);

    window.addEventListener(
      "CookiebotOnAccept",
      function (e) {
        showChargingMap.value = (window as any).Cookiebot.consent.preferences;
      },
      false
    );

    window.addEventListener(
      "CookiebotOnDecline",
      function (e) {
        showChargingMap.value = (window as any).Cookiebot.consent.preferences;
      },
      false
    );

    function acceptCookieConsent() {
      (window as any).Cookiebot.show();
    }

    return {
      infoOptions,
      infoWindowPos,
      center,
      open,
      clickMarker,
      markers,
      showChargingMap,
      acceptCookieConsent,
      places: [],
    };
  },
});
