var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto"},[(_vm.showChargingMap)?_c('div',{staticClass:"flex flex-col-reverse"},[_vm._m(0),_c('GmapMap',{staticClass:"mx-auto",staticStyle:{"width":"100%","height":"550px"},attrs:{"center":_vm.center,"zoom":14,"map-type-id":"terrain"}},[_c('GmapInfoWindow',{staticClass:"focus:shadow-none",attrs:{"opened":_vm.open,"options":_vm.infoOptions,"position":_vm.infoWindowPos},on:{"closeclick":function($event){_vm.open = false}}},[_c('div',{staticClass:"text-center outline-none",domProps:{"innerHTML":_vm._s(_vm.infoOptions.content)}})]),_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"icon":m.icon},on:{"click":function($event){return _vm.clickMarker(
            m.position,
            m.name,
            m.street,
            m.streetnumber,
            m.zipcode,
            m.city
          )}}})})],2)],1):_c('div',{staticClass:"text-black",attrs:{"id":"chargingMapText"}},[_c('h2',[_vm._v("Übersicht Ladestationen")]),_c('p',[_vm._v(" Externe Inhalte von Google werden auf Grund Ihrer aktuellen Cookie Einstellungen ausgeblendet. ")]),_c('div',{staticClass:"flex flex-row items-start"},[_c('button',{staticClass:"inline-block hover:no-underline btn btn-secondary mt-2",on:{"click":function($event){return _vm.acceptCookieConsent()}}},[_vm._v(" Cookie Präferenzen akzeptieren um Ladestationen anzuzeigen ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex w-min flex-col md:flex-row mt-2 md:space-x-6 space-y-2 md:space-y-0"},[_c('div',{staticClass:"flex items-center md:space-x-2 space-x-4"},[_c('img',{staticStyle:{"width":"40px","height":"52px"},attrs:{"src":require("@/assets/images/charging-car.png")}}),_c('span',[_vm._v("Ladesäulen für Elektrofahrzeuge")])]),_c('div',{staticClass:"flex items-center md:space-x-2 space-x-4"},[_c('img',{staticStyle:{"width":"40px","height":"52px"},attrs:{"src":require("@/assets/images/charging-fast.png")}}),_c('span',[_vm._v("Ladesäulen für Elektrofahrzeuge – Schnellladestationen")])])])])}]

export { render, staticRenderFns }