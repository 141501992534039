import Vue from "@/helper/initVue";

import * as VueGoogleMaps from 'vue2-google-maps'

import { ChargingStationsMap } from "@/features/charging-stations-map";

const element = document.querySelectorAll(".charging-stations-map");
const elRecord = element[0]?.getAttribute("data-records");

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDv7VOFdAZM0dYmg5KVANOiWUjJ6YBPnhM',
    libraries: 'places'
  }
});

new Vue({
    render: (h) =>
      h(ChargingStationsMap, {
        props: {
            records: elRecord ? JSON.parse(elRecord) : {},
          },
      }),    
  }).$mount(".charging-stations-map");




